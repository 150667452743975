<template>
  <v-container fluid>
    <Header />
    <v-row justify="center" align="center" style="height: 90vh;">
      <v-col cols="12" sm="12" md="6" lg="3" xl="3" style="margin-bottom: 6rem;">
        <v-card class="pa-6">
          <p class="text-md-h6 font-weight-medium text-center">Sign In</p>
          <v-alert
            :value="error"
            transition="scale-transition"
            class="mb-3"
            color="error"
            dense
            text
            dismissible
          ><span class="caption">Invalid E-mail or Password</span></v-alert>
          <v-form ref="form" @submit.prevent="login" lazy-validation>
            <v-flex xs12 sm12 md12>
              <v-text-field
                :rules="[rules.required, rules.email]"
                v-model="credentials.email"
                label="E-Mail"
                hide-details
                outlined
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field
                :rules="[rules.required]"
                v-model="credentials.password"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                label="Password"
                color="primary"
                class="my-2"
                hide-details
                outlined
              >
              </v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md12>
            <div class="text-center">
              <v-btn
                :loading="isLoading"
                color="primary"
                type="submit"
                @click="login()"
                large
                depressed
                block
                dark
              >
                <span>Login</span>
              </v-btn>
            </div>
            <v-divider class="my-4"></v-divider>
          </v-flex>
        </v-form>
        <div class="text-center">
          <v-btn
            color="primary"
            class="mt-2"
            @click="loginGoogle()"
            large
            depressed
            block
            outlined
          >
            <v-icon class="pr-2">mdi-google</v-icon>
            <span>Login with Google</span>
          </v-btn>
          <v-btn
            color="primary"
            class="mt-2"
            @click="loginFacebook()"
            large
            depressed
            block
            outlined
          >
            <v-icon class="pr-2">mdi-facebook</v-icon>
            <span>Login with Facebook</span>
          </v-btn>
        </div>
        </v-card>
        <v-layout align-center row column>
          <v-flex xs12 pt-4>
            <span :class="$vuetify.breakpoint.mdAndUp ? 'font-weight-medium' : 'body-2 font-weight-medium'">Don't have an account yet?
            <router-link to="/register" style="text-decoration: none;">Sign Up</router-link> now.
            </span>
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserService from '@/services/UserService'
import io from 'socket.io-client'
import Navigation from '@/components/RegisterComponents/Navigation'
import Header from '@/components/IndexComponents/Header'
import rulesMixin from '@/mixins/rulesMixin'

export default {
  name: 'Login',
  components: {
    Header,
    Navigation
  },
  mixins: [rulesMixin],
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      isLoading: false,
      showPassword: false,
      error: false,
      credentials: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    loginFacebook () {
      // UserService.loginFacebook()
      window.location.href = `${process.env.VUE_APP_SERVER}/api/users/facebook`
    },
    loginGoogle () {
      // UserService.loginGoogle()
      window.location.href = `${process.env.VUE_APP_SERVER}/api/users/google`
    },
    getCsrfToken () {
      this.$store.dispatch('auth/getCsrfToken')
        .catch(err => {
          this.$swal({
            type: 'error',
            text: 'Something went wrong with the server.',
            showConfirmButton: false,
            showCloseButton: true
          })
        })
    },
    login () {
      if (this.$refs.form.validate()) {
        this.isLoading = true
        this.error = false
        this.$store.dispatch('auth/SET_TOKEN', {
          email: this.credentials.email,
          password: this.credentials.password
        })
          .then(res => {
            if (res) {
              this.$router.push('/jobs')
            } else {
              this.$router.push('/post-problem')
            }
          })
          .catch(err => {
            this.error = true
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }
  },
  mounted () {
    this.getCsrfToken()
  }
}
</script>


